/* eslint-disable @typescript-eslint/ban-types */
import dayjs from "dayjs";
import {
  getAllAbsenceReasons,
  getAllActiveAbsenceReasons,
} from "../absenceReason/AbsenceReason.axios";
import { getAllActiveBikes, getAllBikes } from "../bike/Bike.axios";
import { getAllBranches } from "../branche/Branche.axios";
import {
  getAllActiveBusinessAreas,
  getAllBusinessAreas,
} from "../businessArea/BusinessArea.axios";
import {
  getAllActiveCars,
  getAllAvailableCars,
  getAllAvailableCarsForUser,
  getAllCars,
  getAllSchedulesForCar,
} from "../car/Car.axios";
import { getAllChanges } from "../changeHistory/ChangeHistory.axios";
import {
  getAllActiveCompanies,
  getAllCompanies,
} from "../company/Company.axios";
import {
  getAllCrmActionEntries,
  getAllCrmActions,
  getAllCrmEntries,
  getAllCrmTypes,
} from "../crm/Crm.axios";
import {
  getAllActiveCurrencies,
  getAllActiveCurrencyConversions,
  getAllCurrencies,
} from "../currency/Currency.axios";
import {
  getAllActiveCustomerUsers,
  getAllActiveCustomers,
  getAllCustomerUsers,
  getAllCustomers,
} from "../customer/Customer.axios";
import {
  getAllActiveCustomerArticles,
  getAllCustomerArticles,
} from "../customerArticle/CustomerArticle.axios";
import { getAllCustomerFeedbackEntries } from "../customerFeedbackEntry/CustomerFeedbackEntry.axios";
import {
  getAllActiveCustomerLocations,
  getAllCustomerLocations,
} from "../customerLocation/CustomerLocation.axios";
import {
  getAllActiveDepartments,
  getAllDepartments,
} from "../department/Department.axios";
import { getAllDunningLevels } from "../dunningLevel/DunningLevel.axios";
import {
  getAllHolidays,
  getAllHolidaysForUser,
  getAllSubordinateHolidayRequests,
} from "../holiday/Holiday.axios";
import { getAllInventoryItems } from "../inventory/Inventory.axios";
import {
  getAllDueInvoices,
  getAllInvoicePayments,
  getAllPaymentConditions,
} from "../invoice/Invoice.axios";
import {
  getCustomerFeedbackEntries,
  getEmployeeUtilizationOverview,
  getEmploymentOverview,
  getNewCustomerOverview,
  getRevenueOverview,
  getTop10CustomerOverview,
} from "../kpi/kpi.axios";
import { getAllOffers } from "../offer/Offer.axios";
import { OfferType } from "../offer/Offer.types";
import {
  getAllActiveOffices,
  getAllOffices,
  getOfficeById,
} from "../office/Office.axios";
import { getAllOfficialHolidays } from "../officialHoliday/OfficialHoliday.axios";
import { getAllNumberRanges, getAllOrders } from "../order/Order.axios";
import { getAllPdls } from "../pdl/Pdl.axios";
import {
  getAllActiveProjects,
  getAllProjectProtocols,
  getAllProjects,
  getAllProjectSchedulesForStartDate,
} from "../project/Project.axios";
import {
  getAllActiveTasks,
  getAllStandardTasks,
  getAllTasks,
} from "../tasks/Task.axios";
import { getAllTimeTrackingsByUserId } from "../time/TimeTracking.axios";
import {
  getAllSchedulesForUser,
  loadAllActiveSchambeckUsers,
  loadAllSimpleUsersAllOffices,
  loadAllSchambeckUsersForOffices,
  loadAllSimpleUsers,
  loadAllSchambeckUsers,
} from "../user/User.axios";
import { getAllUserFunctions } from "../userFunction/UserFunction.axios";
import { getAllUserLanguages } from "../userLanguage/UserLanguage.axios";
import {
  getAllActiveUserRoles,
  getAllUserRoles,
} from "../userRole/UserRole.axios";
import { getAllWorkEquipmentEntries } from "../workEquipment/WorkEquipment.axios";
import {
  getAllActiveWorkQualifications,
  getAllWorkQualifications,
} from "../workQualification/WorkQualification.axios";
import { DataFetchers } from "./data.types";
import { getAllActiveLockDates } from "../LockDate/LockDate.axios";

/**
 * Defines all data types which can be fetched as keys (which can be used in the `useData` hook).
 * The value is the dependency for the data to be fetched.
 *
 * Example: data type `USERS_BY_CUSTOMER` needs a `customerUid` to be fetched, so the value is { customerUid: string }
 *
 * Axios and the current user are always passed to the fetcher function.
 */
export type Data = {
  OFFICES_ALL: {} | undefined;
  OFFICES_ALL_ACTIVE: {} | undefined;
  OFFICES_ID: { officeId: string };
  SCHAMBECK_USERS_ALL_ACTIVE: {} | undefined;
  SCHAMBECK_USERS_ALL_ALL_OFFICES: {} | undefined;
  SCHAMBECK_USERS_ALL: {} | undefined;
  TIMETRACKING_ALL_USER: { userId: string };
  OFFICIAL_HOLIDAYS_ALL: {} | undefined;
  HOLIDAYS_ALL: {} | undefined;
  HOLIDAYS_ALL_USER: { userId: string };
  HOLIDAYS_ALL_SUBS: { userId: string };
  ABSENCE_REASONS_ALL: {} | undefined;
  ABSENCE_REASONS_ALL_ACTIVE: {} | undefined;
  ROLES_ALL: {} | undefined;
  ROLES_ALL_ACTIVE: {} | undefined;
  CARS_ALL_ACTIVE: {} | undefined;
  CARS_ALL: {} | undefined;
  CARS_ALL_AVAILABLE: {} | undefined;
  CARS_ALL_AVAILABLE_USER: { carId: string };
  BIKES_ALL: {} | undefined;
  BIKES_ALL_ACTIVE: {} | undefined;
  SIMPLEUSERS_ALL: {} | undefined;
  SIMPLEUSERS_ALL_ALL_OFFICES: {} | undefined;
  TASKS_ALL: {} | undefined;
  TASKS_ALL_ACTIVE: {} | undefined;
  TASKS_ALL_STANDARD: {} | undefined;
  CUSTOMER_ALL: {} | undefined;
  CUSTOMER_ALL_ACTIVE: {} | undefined;
  BRANCHES_ALL: {} | undefined;
  CUSTOMER_USERS_ALL: {} | undefined;
  CUSTOMER_USERS_ALL_ACTIVE: {} | undefined;
  CUSTOMER_LOCATION_ALL: {} | undefined;
  CUSTOMER_LOCATION_ALL_ACTIVE: {} | undefined;
  ORDER_ALL: {} | undefined;
  CURRENCY_ALL: {} | undefined;
  CURRENCY_ALL_ACTIVE: {} | undefined;
  CURRENCY_CONVERSIONS_ACTIVE: {} | undefined;
  NUMBER_RANGE_ALL: {} | undefined;
  PROJECT_ALL: {} | undefined;
  PROJECT_ALL_ACTIVE: {} | undefined;
  OFFER_ALL: {} | undefined;
  PROJECT_PLANNING_ALL: { startDate: dayjs.Dayjs };
  QUALIFICATIONS_ALL: {} | undefined;
  QUALIFICATIONS_ALL_ACTIVE: {} | undefined;
  COMPANY_ALL: {} | undefined;
  COMPANY_ALL_ACTIVE: {} | undefined;
  PAYMENT_CONDITION_ALL: {} | undefined;
  CHANGE_HISTORY_ALL: {} | undefined;
  FEEDBACK_ALL: {} | undefined;
  WORK_EQUIPMENT_ALL: {} | undefined;
  DUNNING_ALL: {} | undefined;
  BUSINESSAREA_ALL: {} | undefined;
  BUSINESSAREA_ALL_ACTIVE: {} | undefined;
  USER_LANGUAGE_ALL: {} | undefined;
  PROTOCOL_ALL: {} | undefined;
  USER_FUNCTION_ALL: {} | undefined;
  DEPARTMENT_ALL: {} | undefined;
  DEPARTMENT_ALL_ACTIVE: {} | undefined;
  PDL_ALL: {} | undefined;
  CRM_TYPES_ALL: {} | undefined;
  CRM_ACTIONS_ALL: {} | undefined;
  CRM_ENTRIES_ALL: {} | undefined;
  CRM_ACTION_ENTRIES_ALL: {} | undefined;
  SCHEDULE_USER_ALL: { referenceId: string };
  SCHEDULE_CAR_ALL: { referenceId: string };
  CUSTOMER_ARTICLE_ALL: {} | undefined;
  CUSTOMER_ARTICLE_ALL_ACTIVE: {} | undefined;
  CUSTOMER_PROJECT: {} | undefined;
  INVOICE_DUE: {} | undefined;
  INVENTORY_ALL: {} | undefined;
  FEEDBACK_KPI: {} | undefined;
  TOP_10_KPI: { year: number };
  KPI_EMPLOYEE: {} | undefined;
  KPI_NEW_CUSTOMER: {} | undefined;
  KPI_REVENUE: {} | undefined;
  KPI_EMPLOYEE_PRODUCTIVITY: {} | undefined;
  LOCKDATE_ALL_ACTIVE: {} | undefined;
  PAYMENTS_ALL: {} | undefined;
  /**
   * Can be used to load no data.
   *
   * example: `useData(hasRole(user, UserRole.ADMIN) ? "SOME_DATA": "NONE")`
   */
  NONE: undefined;
};

/**
 * Defines all data fetchers (a async function which returns the desired data).
 *
 * The key is the data type which can be used in the `useData` hook.
 * The value is a object containing the key (swr cache key) and the fetcher function.
 *
 * The fetcher function has a first parameter which is the in `Data` defined dependencies
 * and a second parameter with the axios instance and the current user.
 */
export const dataFetchers = {
  OFFICES_ALL: {
    key: "/offices/all",
    fetcher: (_, { axios }) => getAllOffices(axios),
  },
  OFFICES_ALL_ACTIVE: {
    key: "/offices/all/active",
    fetcher: (_, { axios }) => getAllActiveOffices(axios),
  },
  OFFICES_ID: {
    key: "/offices/all",
    fetcher: (params, { axios }) => getOfficeById(axios, params.officeId),
  },
  SCHAMBECK_USERS_ALL: {
    key: "/users/all/office",
    fetcher: (_, { axios }) => loadAllSchambeckUsersForOffices(axios),
  },
  SCHAMBECK_USERS_ALL_ACTIVE: {
    key: "/users/all/active",
    fetcher: (_, { axios }) => loadAllActiveSchambeckUsers(axios),
  },
  SCHAMBECK_USERS_ALL_ALL_OFFICES: {
    key: "/users/all",
    fetcher: (_, { axios }) => loadAllSchambeckUsers(axios),
  },
  TIMETRACKING_ALL_USER: {
    key: "/timetracking/all/user",
    fetcher: (params, { axios }) =>
      getAllTimeTrackingsByUserId(axios, params.userId),
  },
  OFFICIAL_HOLIDAYS_ALL: {
    key: "/officialholidays/all",
    fetcher: (_, { axios }) => getAllOfficialHolidays(axios),
  },
  HOLIDAYS_ALL: {
    key: "holidays/all",
    fetcher: (_, { axios }) => getAllHolidays(axios),
  },
  HOLIDAYS_ALL_USER: {
    key: "holidays/all/user",
    fetcher: (params, { axios }) => getAllHolidaysForUser(params.userId, axios),
  },
  HOLIDAYS_ALL_SUBS: {
    key: "holidays/all/subs",
    fetcher: (params, { axios }) =>
      getAllSubordinateHolidayRequests(axios, params.userId),
  },
  ABSENCE_REASONS_ALL: {
    key: "absence/reason/all",
    fetcher: (_, { axios }) => getAllAbsenceReasons(axios),
  },
  ABSENCE_REASONS_ALL_ACTIVE: {
    key: "absence/reason/all/active",
    fetcher: (_, { axios }) => getAllActiveAbsenceReasons(axios),
  },
  ROLES_ALL: {
    key: "roles/",
    fetcher: (_, { axios }) => getAllUserRoles(axios),
  },
  ROLES_ALL_ACTIVE: {
    key: "roles/",
    fetcher: (_, { axios }) => getAllActiveUserRoles(axios),
  },
  CARS_ALL: {
    key: "car/",
    fetcher: (_, { axios }) => getAllCars(axios),
  },
  CARS_ALL_ACTIVE: {
    key: "car/active",
    fetcher: (_, { axios }) => getAllActiveCars(axios),
  },
  CARS_ALL_AVAILABLE: {
    key: "car/available/",
    fetcher: (_, { axios }) => getAllAvailableCars(axios),
  },
  CARS_ALL_AVAILABLE_USER: {
    key: "car/available/user",
    fetcher: (params, { axios }) =>
      getAllAvailableCarsForUser(axios, params.carId),
  },
  BIKES_ALL: {
    key: "bike/",
    fetcher: (_, { axios }) => getAllBikes(axios),
  },
  BIKES_ALL_ACTIVE: {
    key: "bike/active/",
    fetcher: (_, { axios }) => getAllActiveBikes(axios),
  },
  SIMPLEUSERS_ALL: {
    key: "user/simple/all/office",
    fetcher: (_, { axios }) => loadAllSimpleUsers(axios),
  },
  SIMPLEUSERS_ALL_ALL_OFFICES: {
    key: "user/simple/all/",
    fetcher: (_, { axios }) => loadAllSimpleUsersAllOffices(axios),
  },
  TASKS_ALL: { key: "task/all", fetcher: (_, { axios }) => getAllTasks(axios) },
  TASKS_ALL_ACTIVE: {
    key: "task/all/active",
    fetcher: (_, { axios }) => getAllActiveTasks(axios),
  },
  TASKS_ALL_STANDARD: {
    key: "task/all/standard",
    fetcher: (_, { axios }) => getAllStandardTasks(axios),
  },
  CUSTOMER_ALL: {
    key: "customer/all",
    fetcher: (_, { axios }) => getAllCustomers(axios),
  },
  CUSTOMER_ALL_ACTIVE: {
    key: "customer/all/active",
    fetcher: (_, { axios }) => getAllActiveCustomers(axios),
  },
  BRANCHES_ALL: {
    key: "branch/all",
    fetcher: (_, { axios }) => getAllBranches(axios),
  },
  CUSTOMER_USERS_ALL: {
    key: "customer/user",
    fetcher: (_, { axios }) => getAllCustomerUsers(axios),
  },
  CUSTOMER_USERS_ALL_ACTIVE: {
    key: "customer/user",
    fetcher: (_, { axios }) => getAllActiveCustomerUsers(axios),
  },
  CUSTOMER_LOCATION_ALL: {
    key: "customer/location/all",
    fetcher: (_, { axios }) => getAllCustomerLocations(axios),
  },
  CUSTOMER_LOCATION_ALL_ACTIVE: {
    key: "customer/location/all/active",
    fetcher: (_, { axios }) => getAllActiveCustomerLocations(axios),
  },
  ORDER_ALL: {
    key: "order/all",
    fetcher: (_, { axios }) => getAllOrders(axios),
  },
  CURRENCY_ALL: {
    key: "currency/all",
    fetcher: (_, { axios }) => getAllCurrencies(axios),
  },
  CURRENCY_ALL_ACTIVE: {
    key: "currency/all/active",
    fetcher: (_, { axios }) => getAllActiveCurrencies(axios),
  },
  CURRENCY_CONVERSIONS_ACTIVE: {
    key: "currency/conversion/active",
    fetcher: (_, { axios }) => getAllActiveCurrencyConversions(axios),
  },
  NUMBER_RANGE_ALL: {
    key: "numberRanges/all",
    fetcher: (_, { axios }) => getAllNumberRanges(axios),
  },
  PROJECT_ALL: {
    key: "project/all",
    fetcher: (_, { axios }) => getAllProjects(axios),
  },
  PROJECT_ALL_ACTIVE: {
    key: "project/all/active",
    fetcher: (_, { axios }) => getAllActiveProjects(axios),
  },
  OFFER_ALL: {
    key: "offer/all",
    fetcher: (_, { axios }) => getAllOffers(axios),
  },
  PROJECT_PLANNING_ALL: {
    key: "project/planning/all",
    fetcher: (params, { axios }) =>
      getAllProjectSchedulesForStartDate(params.startDate, axios),
  },
  QUALIFICATIONS_ALL: {
    key: "qualification/all",
    fetcher: (_, { axios }) => getAllWorkQualifications(axios),
  },
  QUALIFICATIONS_ALL_ACTIVE: {
    key: "qualification/all/active",
    fetcher: (_, { axios }) => getAllActiveWorkQualifications(axios),
  },
  COMPANY_ALL: {
    key: "company/all",
    fetcher: (_, { axios }) => getAllCompanies(axios),
  },
  COMPANY_ALL_ACTIVE: {
    key: "company/all/active",
    fetcher: (_, { axios }) => getAllActiveCompanies(axios),
  },
  PAYMENT_CONDITION_ALL: {
    key: "payment/all",
    fetcher: (_, { axios }) => getAllPaymentConditions(axios),
  },
  CHANGE_HISTORY_ALL: {
    key: "changes/all",
    fetcher: (_, { axios }) => getAllChanges(axios),
  },
  FEEDBACK_ALL: {
    key: "customer/feedback/all",
    fetcher: (_, { axios }) => getAllCustomerFeedbackEntries(axios),
  },
  WORK_EQUIPMENT_ALL: {
    key: "workequipment/all",
    fetcher: (_, { axios }) => getAllWorkEquipmentEntries(axios),
  },
  DUNNING_ALL: {
    key: "dunning/all",
    fetcher: (_, { axios }) => getAllDunningLevels(axios),
  },
  BUSINESSAREA_ALL: {
    key: "businessarea/all",
    fetcher: (_, { axios }) => getAllBusinessAreas(axios),
  },
  BUSINESSAREA_ALL_ACTIVE: {
    key: "businessarea/all/active",
    fetcher: (_, { axios }) => getAllActiveBusinessAreas(axios),
  },
  USER_LANGUAGE_ALL: {
    key: "language/all",
    fetcher: (_, { axios }) => getAllUserLanguages(axios),
  },
  PROTOCOL_ALL: {
    key: "project/protocol/all",
    fetcher: (_, { axios }) => getAllProjectProtocols(axios),
  },
  USER_FUNCTION_ALL: {
    key: "user/function/all",
    fetcher: (_, { axios }) => getAllUserFunctions(axios),
  },
  DEPARTMENT_ALL: {
    key: "department/all",
    fetcher: (_, { axios }) => getAllDepartments(axios),
  },
  DEPARTMENT_ALL_ACTIVE: {
    key: "department/all/active",
    fetcher: (_, { axios }) => getAllActiveDepartments(axios),
  },
  PDL_ALL: {
    key: "pdl/all",
    fetcher: (_, { axios }) => getAllPdls(axios),
  },
  CRM_ACTIONS_ALL: {
    key: "crm/actions/all",
    fetcher: (_, { axios }) => getAllCrmActions(axios),
  },
  CRM_ENTRIES_ALL: {
    key: "crm/entries/all",
    fetcher: (_, { axios }) => getAllCrmEntries(axios),
  },
  CRM_TYPES_ALL: {
    key: "crm/types/all",
    fetcher: (_, { axios }) => getAllCrmTypes(axios),
  },
  CRM_ACTION_ENTRIES_ALL: {
    key: "crm/entryaction/all",
    fetcher: (_, { axios }) => getAllCrmActionEntries(axios),
  },
  SCHEDULE_USER_ALL: {
    key: "schedules/user/all",
    fetcher: (params, { axios }) =>
      getAllSchedulesForUser(params.referenceId, axios),
  },
  SCHEDULE_CAR_ALL: {
    key: "schedules/car/all",
    fetcher: (params, { axios }) =>
      getAllSchedulesForCar(params.referenceId, axios),
  },
  CUSTOMER_ARTICLE_ALL: {
    key: "customer/articles/all",
    fetcher: (_, { axios }) => getAllCustomerArticles(axios),
  },
  CUSTOMER_ARTICLE_ALL_ACTIVE: {
    key: "customer/articles/all/active",
    fetcher: (_, { axios }) => getAllActiveCustomerArticles(axios),
  },
  CUSTOMER_PROJECT: {
    key: "customer/project",
    fetcher: (_, { axios }) => getAllOffers(axios, OfferType.CUSTOMER_PROJECT),
  },
  INVOICE_DUE: {
    key: "invoice/due",
    fetcher: (_, { axios }) => getAllDueInvoices(axios),
  },
  INVENTORY_ALL: {
    key: "inventory/all",
    fetcher: (_, { axios }) => getAllInventoryItems(axios),
  },
  FEEDBACK_KPI: {
    key: "feedback/kpi",
    fetcher: (_, { axios }) => getCustomerFeedbackEntries(axios),
  },
  TOP_10_KPI: {
    key: "kpi/customer",
    fetcher: ({ year }, { axios }) => getTop10CustomerOverview(axios, year),
  },
  KPI_EMPLOYEE: {
    key: "kpi/employee",
    fetcher: (_, { axios }) => getEmploymentOverview(axios),
  },
  KPI_NEW_CUSTOMER: {
    key: "kpi/customer/new",
    fetcher: (_, { axios }) => getNewCustomerOverview(axios),
  },
  KPI_REVENUE: {
    key: "kpi/revenue",
    fetcher: (_, { axios }) => getRevenueOverview(axios),
  },
  KPI_EMPLOYEE_PRODUCTIVITY: {
    key: "kpi/employee/productivity",
    fetcher: (_, { axios }) => getEmployeeUtilizationOverview(axios),
  },
  LOCKDATE_ALL_ACTIVE: {
    key: "lockdate/active",
    fetcher: (_, { axios }) => getAllActiveLockDates(axios),
  },
  PAYMENTS_ALL: {
    key: "payments/all",
    fetcher: (_, { axios }) => getAllInvoicePayments(axios),
  },
  NONE: {
    key: "none",
    fetcher: () => Promise.resolve(undefined),
  },
} satisfies DataFetchers<Data>;
