import { Checkbox, TableRow } from "@sam/components";
import { NavigateFunction } from "react-router-dom";
import {
  CrmAction,
  CrmActionEntry,
  CrmEntry,
  CrmManageType,
  CrmType,
  Customer,
  CustomerUser,
  getHtmlTextContent,
  Language,
  Office,
  SimpleUser,
} from "shared";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import i18n from "../../i18n/i18n";
import { getUserNameForSimpleUser } from "../user/User.utils";

/**
 * Helper to quickly generate an empty CrmEntry
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmEntry = (
  override?: Partial<CrmEntry>
): CrmEntry => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  officeId: "",
  typeId: "",
  ...override,
});

/**
 * Helper to quickly generate an empty CrmActionEntry
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmActionEntry = (
  override?: Partial<CrmActionEntry>
): CrmActionEntry => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  actionId: "",
  crmEntryId: "",
  done: false,
  internalContact: [],
  revenueRelevant: false,
  ...override,
});

/**
 * Helper to quickly generate an empty CrmAction
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmAction = (
  override?: Partial<CrmAction>
): CrmAction => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  names: new Map<Language, string>(),
  title: "",
  ...override,
});

/**
 * Helper to quickly generate an empty CrmType
 *
 * @param override Optional parameter override
 * @returns The new instance
 */
export const generateEmptyCrmType = (override?: Partial<CrmType>): CrmType => ({
  id: undefined!,
  createDate: undefined!,
  createdBy: undefined!,
  names: new Map<Language, string>(),
  title: "",
  ...override,
});

/**
 * Helper to quickly create a new manage type. This method works as long as the
 * two objects are the same!
 *
 * @param type The type of manage item to create
 * @param override Optional override params
 * @returns The newly created CrmAction or CrmType
 */
export const generateEmptyManageType = (
  type: CrmManageType,
  override?: Partial<CrmType | CrmAction>
): CrmType | CrmAction => {
  if (type === CrmManageType.ACTION) return generateEmptyCrmAction(override);
  else return generateEmptyCrmType(override);
};

/**
 * Util method to convert either crm types or actions into tableRows. This works as
 * long as the two models are the same!
 *
 * @param entries to convert
 * @param navigate navigateFunction to handle edit click
 * @returns Array of TableRows
 */
export const convertCrmManageEntriesIntoTableEntries = (
  entries: CrmType[] | CrmAction[],
  navigate: NavigateFunction
): TableRow[] => {
  return entries.map((entry) => ({
    id: entry.id,
    onClick: () => navigate("edit", { state: { entry } }),
    content: [
      entry.title,
      entry.createDate.toLocaleDateString("de-DE") || "-",
      entry.lastUpdated?.toLocaleDateString("de-DE") || "-",
      <EditIcon
        title={i18n.t("general.icons.edit")}
        width={30}
        onClick={(evt) => {
          evt.stopPropagation();
          navigate("edit", { state: { entry } });
        }}
      />,
    ],
  }));
};

/**
 * Util method to display crmActionEntries as tableRows
 * @param entries connected  to the actionEntries
 * @param actionEntries the entries to display
 * @param allTypes all available crm types
 * @param allActions all crm actions
 * @param allUsers Array of simpleUsers
 * @param navigate navigate function
 * @param allCustomer list of all customers
 * @param allContacts list of all customerContacts
 * @param offices list of all offices
 * @param language language to create the rows in
 * @returns Array of TableRows
 */
export const convertCrmEntriesIntoTableEntries = (
  entries: CrmEntry[],
  actionEntries: CrmActionEntry[],
  allTypes: CrmType[],
  allActions: CrmAction[],
  allUsers: SimpleUser[],
  navigate: NavigateFunction,
  allCustomer: Customer[],
  allContacts: CustomerUser[],
  offices: Office[],
  language: Language
): TableRow[] => {
  return actionEntries.map((actionEntry) => {
    const crmEntry: CrmEntry | undefined = entries.find(
      (entry) => entry.id === actionEntry.crmEntryId
    );
    const office: Office | undefined = offices.find(
      (office) => office.id === crmEntry?.officeId
    );
    const type: CrmType | undefined = allTypes.find(
      (type) => type.id === crmEntry?.typeId
    );
    const customer: Customer | undefined = allCustomer.find(
      (customer) => customer.id === crmEntry?.customerId
    );
    const contacts: string[] = allContacts
      .filter((contact) => crmEntry?.contactIds?.includes(contact.id))
      .map(
        (relevantContact) =>
          relevantContact.firstName + " " + relevantContact.lastName
      );
    const action: CrmAction | undefined = allActions.find(
      (action) => action.id === actionEntry.actionId
    );
    const createUser: string = getUserNameForSimpleUser(
      actionEntry.createdBy,
      allUsers
    );
    const updateUser: string = getUserNameForSimpleUser(
      actionEntry.updatedBy,
      allUsers
    );
    return {
      id: actionEntry.id,
      onClick: () => navigate("edit", { state: { actionEntry, crmEntry } }),
      content: [
        <Checkbox isChecked={actionEntry.done} disabled />,
        office?.name || "-",
        type?.names.get(language) || "-",
        customer?.name || crmEntry?.customerName || "-",
        contacts.length > 0
          ? contacts.join(", ")
          : crmEntry?.contactName || "-",
        getHtmlTextContent(actionEntry.note),
        action?.names.get(language) || "-",
        actionEntry.createDate.toLocaleDateString("de-DE") || "-",
        createUser,
        actionEntry?.lastUpdated?.toLocaleDateString("de-DE") || "-",
        updateUser,
        actionEntry?.dueDate?.toLocaleDateString("de-DE") || "-",
      ],
    };
  });
};

/**
 * Util method to convert the actions for a specific crmEntry to tableRows
 * @param actionEntries all entries to display
 * @param allActions all possible actions
 * @param allTypes all possible types
 * @param crmEntry the connected crm entry
 * @param allUsers list of all users
 * @param updateActions update method
 * @param setActionToEdit setState action to select an entry to edit
 * @returns Array of tablerows
 */
export const convertCrmActionEntriesIntoTableRows = (
  actionEntries: CrmActionEntry[],
  allActions: CrmAction[],
  allTypes: CrmType[],
  crmEntry: CrmEntry,
  allUsers: SimpleUser[],
  updateActions: (
    value: boolean,
    key: keyof CrmActionEntry,
    actionId: string
  ) => void,
  setActionToEdit: (entry: CrmActionEntry) => void
): TableRow[] => {
  return actionEntries.map((entry) => {
    const action: CrmAction | undefined = allActions.find(
      (act) => act.id === entry.actionId
    );

    const crmType: CrmType | undefined = allTypes.find(
      (type) => type.id === crmEntry?.typeId
    );
    return {
      id: entry.id,
      onClick: () => setActionToEdit(entry),
      content: [
        <Checkbox
          isChecked={entry.done}
          onCheck={(checked) => updateActions(checked, "done", entry.id)}
        />,
        crmType?.title || "-",
        action?.title || "-",
        getHtmlTextContent(entry?.note),
        entry.createDate.toLocaleDateString("de-DE"),
        getUserNameForSimpleUser(entry.createdBy, allUsers),
        entry?.updatedBy
          ? getUserNameForSimpleUser(entry?.updatedBy, allUsers)
          : "-",
        entry.lastUpdated?.toLocaleDateString("de-DE") || "-",
        entry.dueDate?.toLocaleDateString("de-DE") || "-",
      ],
    };
  });
};
