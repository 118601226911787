import { ContentState, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Wysiwyg.scss";
import { WysiwygProps } from "./Wysiwyg.types";

export const Wysiwyg: React.FC<WysiwygProps> = ({
  onChange,
  title,
  disabled = false,
  initialContent = "",
}) => {
  const [editorState, setEditorState] = useState<EditorState>();
  const [localContent, setLocalContent] = useState<string>();

  //Hook to set the inititalstate at the first render
  useEffect(() => {
    if (typeof initialContent !== "string") return;
    if (localContent === initialContent) return;
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(initialContent).contentBlocks
        )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialContent, localContent]);

  return (
    <div className="wysiwyg-wrapper">
      {title && <p className="wysiwyg-editor__title">{title}</p>}
      <Editor
        wrapperClassName="wysiwyg-editor__wrapper"
        editorClassName="wysiwyg-editor"
        localization={{ locale: "de" }}
        editorState={editorState}
        onEditorStateChange={(newEditorState) =>
          !disabled && setEditorState(newEditorState)
        }
        onChange={(event) => {
          if (disabled) return;
          onChange(draftToHtml(event));
          setLocalContent(draftToHtml(event));
        }}
        toolbar={{
          // attributes for customization available at: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
          options: [
            "list",
            "inline",
            "image",
            "embedded",
            "colorPicker",
            "fontSize",
            "textAlign",
          ],
          inline: { options: ["bold", "italic", "underline"] },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["left", "center", "right", "justify"],
            left: { className: undefined },
            center: { className: undefined },
            right: { className: undefined },
            justify: { className: undefined },
          },
          fontSize: {
            options: ["2", "4", "6", "8", "10", "12", "14", "16", "18", "20"],
          },
        }}
      />
    </div>
  );
};
