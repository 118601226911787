import {
  Box,
  Button,
  Dropdown,
  Input,
  Option,
  Popup,
  Table,
  TableRow,
  TextArea,
  TopBar,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Company,
  Customer,
  CustomerTaskPrice,
  DocumentBucket,
  InvoiceMailConfiguration,
  InvoicePosition,
  InvoiceState,
  Language,
  MailType,
  Offer,
  Order,
  Period,
  Receipt,
  Task,
  createAndSendCancelReceipt,
  createAndSendCreditNote,
  generateDropdownOptions,
  generateNotification,
  getCustomerById,
  getCustomerUserById,
  getErrorPatternTablesForInvoice,
  getInvoicePDFDocument,
  getOfferById,
  getOfficeById,
  getOrderById,
  getTimeTablesForInvoice,
  sendInvoiceMail,
  updateInvoice,
  updateInvoicePeriod,
  updateOrder,
  useData,
} from "shared";
import { getCompanyById } from "shared/src/company/Company.axios";
import { CustomerLocation } from "shared/src/customerLocation/CustomerLocation.types";
import { NotificationType } from "shared/src/notification/notification.types";
import { getProjectById } from "shared/src/project/Project.axios";
import dayjs from "shared/src/tools/Dayjs";
import { uid } from "uid";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { FileOverview } from "../../components/files/FileOverview";
import { MailPopup } from "../../components/mailPopup/MailPopup";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { convertInvoicePositionIntoTableEntries } from "../../utils/invoice/Invoice.utils";
import { useLockDate } from "../../utils/lockDate/useLockDate";
import { generateEmptyMailConfiguration } from "../../utils/mail/Mail.utils";
import {
  generateDropdownOptionsForCustomerUsers,
  generateDropdownOptionsForCustomers,
} from "../../utils/order/Order.utils";

export const InvoiceEdit: React.FC = () => {
  const { axios, user, updateFootnoteConfig } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation<{ invoice?: Receipt }>();
  const [invoice, setInvoice] = useState<Receipt | undefined>(
    location.state?.invoice
  );
  const [order, setOrder] = useState<Order>();
  const [activeLockDate] = useLockDate(order?.companyId);
  const [originalOrder, setOriginalOrder] = useState<Order>();
  const [customer, setCustomer] = useState<Customer>();
  const [company, setCompany] = useState<Company>();
  const [documentToShow, setDocumentToShow] = useState<string>();
  const [isEditMode, toggleEditMode] = useState<boolean>(false);
  const [showAddPopup, toggleShowAddPopup] = useState<boolean>(false);
  const isReadOnly: boolean = useMemo(
    (): boolean => invoice?.invoiceState !== InvoiceState.CREATED,
    [invoice?.invoiceState]
  );
  const [offer, setOffer] = useState<Offer>();

  const [isMailConfigOpen, toggleMailConfigOpen] = useState<boolean>(false);
  const [mailConfiguration, setMailConfiguration] =
    useState<InvoiceMailConfiguration>({
      ...generateEmptyMailConfiguration(),
      invoiceId: invoice?.id || "",
      type: MailType.INVOICE,
      userId: user.id,
      language: user.language || Language.DE,
    });

  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const { data: loadedCustomers } = useData("CUSTOMER_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allStandardTasks } = useData("TASKS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerUsers } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: loadedCurrencies } = useData("CURRENCY_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedBusinessAreas } = useData("BUSINESSAREA_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: loadedCustomerLocations } = useData(
    "CUSTOMER_LOCATION_ALL_ACTIVE",
    {
      config: { fallbackData: [] },
    }
  );

  /**
   * DropdownOptions to choose a customer for a different invoiceRecipient
   */
  const customerDropdownOptions: Option[] = useMemo(
    (): Option[] => generateDropdownOptionsForCustomers(loadedCustomers),
    [loadedCustomers]
  );

  /**
   * DropdownOptions to choose a customerUser for a different invoiceRecipient
   */
  const customerUserDropdownOptions: Option[] = useMemo((): Option[] => {
    if (!order?.invoiceRecipient?.customerId) return [];
    return generateDropdownOptionsForCustomerUsers(
      loadedCustomerUsers,
      order.invoiceRecipient.customerId
    );
  }, [loadedCustomerUsers, order?.invoiceRecipient?.customerId]);

  /**
   * Holds the options for the invoice recipient locations
   */
  const invoiceLocationOptions: Option[] = useMemo((): Option[] => {
    if (!order?.invoiceRecipient.customerId) return [];
    const locations: CustomerLocation[] = loadedCustomerLocations.filter(
      (location) => location.customerId === order?.invoiceRecipient.customerId
    );
    return generateDropdownOptions(locations, "name", "id");
  }, [loadedCustomerLocations, order?.invoiceRecipient.customerId]);

  /**
   * Hook to update the footNote containg the create and update data
   */
  useEffect(() => {
    updateFootnoteConfig(invoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.createDate, updateFootnoteConfig]);

  /**
   * Helper method to update a position onChange
   * @param id of the position to update
   * @param key that should be updated
   * @param value of the updated key
   */
  const handlePositionUpdate = useCallback(
    (
      id: string,
      key: keyof InvoicePosition,
      value: string | number | Date
    ): void => {
      if (!invoice) return;
      setInvoice({
        ...invoice,
        invoicePositions: invoice.invoicePositions.map((position) =>
          position.id === id ? { ...position, [key]: value } : position
        ),
      });
    },
    [invoice]
  );

  /**
   * Util method to send an receipt per mail and update the invoiceState
   */
  const handleSendMail = async (): Promise<void> => {
    if (!invoice) return;
    switch (mailConfiguration.type) {
      case MailType.INVOICE:
        return sendInvoiceMail(axios, mailConfiguration).then(
          (updatedInvoice) => {
            if (updatedInvoice) {
              setInvoice(updatedInvoice);
              generateNotification({
                type: NotificationType.SUCCESS,
                value: t("general.notification.success.sendInvoiceMail"),
              });
            }
            toggleMailConfigOpen(false);
          }
        );
      case MailType.CANCEL_RECEIPT:
        return createAndSendCancelReceipt(axios, {
          ...mailConfiguration,
          newReceipt: invoice,
        }).then((createdRecetipt) => {
          if (createdRecetipt) {
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.sendReceipt"),
            });
            navigate("/invoice");
          }
        });
      case MailType.CREDIT_NOTE:
        return createAndSendCreditNote(axios, {
          ...mailConfiguration,
          newReceipt: invoice,
        }).then((receipt) => {
          if (receipt) {
            generateNotification({
              type: NotificationType.SUCCESS,
              value: t("general.notification.success.sendReceipt"),
            });
            navigate("/invoice");
          }
        });
    }
  };

  /**
   * Util method to delete a position from the invoice
   * @param id of the position to delete
   */
  const handleDeletePosition = useCallback(
    (id: string): void => {
      if (!invoice) return;
      setInvoice({
        ...invoice,
        invoicePositions: invoice.invoicePositions.filter(
          (position) => position.id !== id
        ),
      });
    },
    [invoice]
  );

  // TableRows for the different positions of the invoice
  const invoicePositionRows: TableRow[] = useMemo((): TableRow[] => {
    if (!invoice) return [];
    return convertInvoicePositionIntoTableEntries(
      invoice.invoicePositions,
      handlePositionUpdate,
      handleDeletePosition,
      isReadOnly && !isEditMode
    );
  }, [
    handleDeletePosition,
    handlePositionUpdate,
    invoice,
    isEditMode,
    isReadOnly,
  ]);

  //Hook to load order, customer and company to be displayed
  useEffect(() => {
    if (!invoice) return;
    getOrderById(axios, invoice?.orderId).then((loadedOrder) => {
      setOrder(loadedOrder);
      setOriginalOrder(loadedOrder);
      if (loadedOrder?.customerId)
        Promise.all([
          getCustomerById(axios, loadedOrder.customerId),
          getCustomerUserById(
            axios,
            loadedOrder.invoiceRecipient.customerUserId
          ),
          getCompanyById(axios, loadedOrder.companyId),
          getOfficeById(axios, loadedOrder.officeId),
          getProjectById(axios, invoice.projectId),
        ]).then(
          ([
            loadedCustomer,
            loadedInvoiceContact,
            loadedCompany,
            loadedOffice,
            loadedProject,
          ]) => {
            setCustomer(loadedCustomer);
            setCompany(loadedCompany);
            const invoiceRecipientMail: string =
              loadedInvoiceContact?.contact.mail || "";
            setMailConfiguration((old) => ({
              ...old,
              smtpConfig: loadedOffice!.accountingMailConfiguration,
              receiver: invoiceRecipientMail,
            }));
            loadedProject &&
              getOfferById(axios, loadedProject.acceptedOfferId).then(
                (loadedOffer) => {
                  setOffer(loadedOffer);
                }
              );
          }
        );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  /**
   * Helper method to update or create a new Invoice
   * @param redirect decides if the method should navigate back after success
   */
  const handleSubmit = async (redirect: boolean): Promise<void> => {
    button.current?.click();
    if (!form.current?.checkValidity() || !invoice || !order)
      return Promise.resolve();

    if (invoice.id) {
      return Promise.all([
        updateInvoice(axios, invoice),
        updateOrder(axios, order),
      ]).then(([updatedInvoice, updateOrder]) => {
        if (redirect && updatedInvoice) navigate("/invoice");
        if (updatedInvoice && updateOrder) {
          setInvoice(updatedInvoice);
          setOrder(updateOrder);
          generateNotification({
            value: t("general.notification.success.saveSuccessfull"),
            type: NotificationType.SUCCESS,
          });
        }
      });
    } else {
      generateNotification({
        type: NotificationType.INFO,
        value: t("general.notification.info.functionNotAvailable"),
      });
    }
  };

  /**
   * Util method to handle the change of a period and updates the invoice positions for the new protocols
   * @param period updated period of the invoice
   */
  const handlePeriodChange = (period: Period): void => {
    if (!invoice) return;
    if (dayjs(period.endDate).isBefore(period.startDate)) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.unplausibleDates"),
      });
      return;
    } else if (
      dayjs(activeLockDate).isSameOrAfter(period.startDate) ||
      dayjs(activeLockDate).isSameOrAfter(period.endDate)
    ) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.lockedByLockDate"),
      });
      return;
    }
    updateInvoicePeriod(axios, { ...invoice, period }).then((updatedInvoice) =>
      setInvoice(updatedInvoice)
    );
  };

  /**
   * If nothing than the positions waas edited, a creaditNote is possible
   */
  const isCreditNotePossible: boolean = useMemo((): boolean => {
    const originalInvoice: Receipt | undefined = location.state?.invoice;

    if (!originalInvoice) return false;
    else if (
      dayjs(originalInvoice.shownPeriod?.startDate).isSame(
        invoice?.shownPeriod?.startDate,
        "date"
      ) &&
      dayjs(originalInvoice.shownPeriod?.endDate).isSame(
        invoice?.shownPeriod?.endDate,
        "date"
      ) &&
      originalOrder?.invoiceRecipient.customerUserId ===
        order?.invoiceRecipient.customerUserId &&
      originalOrder?.invoiceRecipient.customerId ===
        order?.invoiceRecipient.customerId
    )
      return true;
    return false;
  }, [
    invoice?.shownPeriod?.endDate,
    invoice?.shownPeriod?.startDate,
    location.state?.invoice,
    order?.invoiceRecipient.customerId,
    order?.invoiceRecipient.customerUserId,
    originalOrder?.invoiceRecipient.customerId,
    originalOrder?.invoiceRecipient.customerUserId,
  ]);

  /**
   * Helper method to add an invoicePosition to the invocie
   */
  const handleAddPosition = (taskId: string): void => {
    if (!invoice || !order) return;
    const selectedTask: Task | undefined = allStandardTasks.find(
      (task) => task.id === taskId
    );
    if (!selectedTask) {
      generateNotification({
        type: NotificationType.ERROR,
        value: t("general.notification.error.addTasksToInvoice"),
      });
      return;
    }

    let price: number = selectedTask.price;
    const customerTaskPrice: CustomerTaskPrice | undefined =
      customer?.customPrices.find(
        (customerPrice) => customerPrice.taskId === selectedTask.id
      );
    if (customerTaskPrice) price = customerTaskPrice.price;
    else if (
      selectedTask.officePrices?.get(invoice.officeId)?.get(order.currencyId)
    )
      price = selectedTask.officePrices
        .get(invoice.officeId)!
        .get(order.currencyId)!;
    setInvoice({
      ...invoice,
      invoicePositions: [
        ...invoice.invoicePositions,
        {
          index: invoice.invoicePositions.length + 1,
          price,
          articleNumber: selectedTask.identifier,
          description:
            selectedTask.translations.get(offer?.language || Language.DE) ||
            "-",
          amount: 1,
          discount: 0,
          id: uid(),
          unitCode: selectedTask.unitCode,
        },
      ],
    });
    toggleShowAddPopup(false);
  };
  /**
   * helper method to download the correct protocol
   * @param type to decide what document to create
   */
  const getProtocolDocument = async (
    type: "time" | "pieces"
  ): Promise<void> => {
    if (!invoice?.id) return;
    const document: Blob | undefined =
      type === "time"
        ? await getTimeTablesForInvoice(axios, invoice.id)
        : await getErrorPatternTablesForInvoice(axios, invoice.id);
    if (document) setDocumentToShow(URL.createObjectURL(document));
  };

  return (
    <form
      ref={form}
      onSubmit={(evt) => evt.preventDefault()}
      onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
    >
      <TopBar
        title={t(`pages.invoice.edit.topBarHeadline.${invoice?.receiptType}`, {
          replace: { invoiceNumber: invoice?.invoiceNumber || "" },
        })}
        onBackClick={() => navigate(-1)}
      >
        {(invoice?.invoiceState === InvoiceState.PENDING ||
          invoice?.invoiceState === InvoiceState.EXPORTED) &&
          !isEditMode && (
            <Button
              value={t("general.buttons.edit")}
              onClick={() => toggleEditMode(true)}
            />
          )}
        {isEditMode ? (
          <>
            <Button
              value={t("general.buttons.invoiceCancel")}
              onClick={() => {
                setMailConfiguration({
                  ...mailConfiguration,
                  type: MailType.CANCEL_RECEIPT,
                });
                toggleMailConfigOpen(true);
              }}
            />
            <Button
              value={t("general.buttons.creditNote")}
              disabled={!isCreditNotePossible}
              onClick={() => {
                setMailConfiguration({
                  ...mailConfiguration,
                  type: MailType.CREDIT_NOTE,
                });
                toggleMailConfigOpen(true);
              }}
            />
            <Button
              value={t("general.buttons.cancel")}
              onClick={() => {
                setInvoice(location.state?.invoice);
                toggleEditMode(false);
              }}
            />
          </>
        ) : (
          <SaveButtons handleSubmit={handleSubmit} buttonRef={button} />
        )}
      </TopBar>
      <MailPopup
        sendDates={invoice?.sendDates}
        sendMail={handleSendMail}
        isOpen={isMailConfigOpen}
        toggleOpen={toggleMailConfigOpen}
        updateConfig={(updatedConfig) =>
          setMailConfiguration((old) => ({ ...old, ...updatedConfig }))
        }
        mailConfig={mailConfiguration}
        referenceId={invoice?.id || ""}
        userId={user.id}
      />
      {documentToShow && (
        <Popup
          isOpen={!!documentToShow}
          onClose={() => setDocumentToShow(undefined)}
        >
          <object
            data={documentToShow}
            type={"application/pdf"}
            width="1000px"
            height="700px"
          />
        </Popup>
      )}
      {offer && (
        <Popup
          isOpen={showAddPopup}
          title={t("pages.invoice.edit.addPosition")}
          buttons={[
            <Button
              value={t("general.buttons.cancel")}
              onClick={() => toggleShowAddPopup(false)}
            />,
          ]}
        >
          <div className="invoice-edit__add-position">
            <Dropdown
              options={generateDropdownOptions(allStandardTasks, "title", "id")}
              onChange={handleAddPosition}
            />
          </div>
        </Popup>
      )}
      <Box title={t("pages.invoice.edit.baseData")}>
        <div className="three-columns">
          <Input
            type="text"
            label={t("pages.invoice.edit.customer")}
            value={customer?.name}
            onChange={() => {}}
            disabled
          />
          <Input
            type="text"
            value={company?.name}
            label={t("pages.invoice.edit.company")}
            onChange={() => {}}
            disabled
          />
          <Input
            label={t("pages.invoice.edit.createDate")}
            value={invoice?.createDate}
            type="date"
            disabled
            onChangeDate={() => {}}
          />
          <Input
            label={t("pages.invoice.edit.invoiceDate")}
            value={invoice?.invoiceDate}
            type="date"
            disabled={isReadOnly}
            onChangeDate={(invoiceDate) => {
              if (!invoice || !invoiceDate) return;
              setInvoice({
                ...invoice,
                invoiceDate,
                dueDate: dayjs(invoiceDate)
                  .add(order?.paymentCondition?.daysToPay || 0, "day")
                  .toDate(),
              });
            }}
          />
          <Input
            label={t("pages.invoice.edit.periodStart")}
            value={invoice?.period.startDate}
            type="date"
            disabled={isReadOnly}
            onChangeDate={(startDate) =>
              startDate &&
              invoice &&
              handlePeriodChange({
                startDate,
                endDate: invoice.period.endDate,
              })
            }
          />
          <Input
            label={t("pages.invoice.edit.periodEnd")}
            value={invoice?.period.endDate}
            type="date"
            disabled={isReadOnly}
            onChangeDate={(endDate) =>
              endDate &&
              invoice &&
              handlePeriodChange({
                endDate,
                startDate: invoice.period.startDate,
              })
            }
          />
          <Input
            label={t("pages.invoice.edit.deliveryDate")}
            value={invoice?.deliveryDate}
            type="date"
            disabled={isReadOnly}
            onChangeDate={(deliveryDate) =>
              invoice &&
              deliveryDate &&
              setInvoice({ ...invoice, deliveryDate })
            }
          />
          <Input
            label={t("pages.invoice.edit.shownPeriodStart")}
            value={invoice?.shownPeriod?.startDate}
            type="date"
            disabled={isReadOnly && !isEditMode}
            onChangeDate={(startDate) =>
              startDate &&
              invoice &&
              setInvoice({
                ...invoice,
                shownPeriod: { ...invoice.shownPeriod, startDate },
              })
            }
          />
          <Input
            label={t("pages.invoice.edit.shownPeriodEnd")}
            value={invoice?.shownPeriod?.endDate}
            type="date"
            disabled={isReadOnly && !isEditMode}
            onChangeDate={(endDate) =>
              endDate &&
              invoice &&
              setInvoice({
                ...invoice,
                shownPeriod: { ...invoice.shownPeriod, endDate },
              })
            }
          />
          {order && (
            <>
              <Dropdown
                disabled={isReadOnly && !isEditMode}
                selectedOption={order.invoiceRecipient?.customerId}
                label={t("pages.invoice.edit.invoiceRecipient")}
                options={customerDropdownOptions}
                onChange={(customerId) =>
                  setOrder({
                    ...order,
                    invoiceRecipient: {
                      customerUserId: "",
                      customerLocationId: "",
                      customerId,
                    },
                  })
                }
              />
              <Dropdown
                disabled={isReadOnly && !isEditMode}
                selectedOption={order.invoiceRecipient?.customerLocationId}
                label={t("pages.project.edit.invoiceRecipientLocation")}
                options={invoiceLocationOptions}
                onChange={(customerLocationId) =>
                  setOrder({
                    ...order,
                    invoiceRecipient: {
                      customerId: order.invoiceRecipient.customerId,
                      customerLocationId,
                      customerUserId: "",
                    },
                  })
                }
              />
              <Dropdown
                disabled={
                  !order.invoiceRecipient?.customerId ||
                  (isReadOnly && !isEditMode)
                }
                selectedOption={order.invoiceRecipient?.customerUserId}
                label={t("pages.project.edit.invoiceContact")}
                options={customerUserDropdownOptions}
                onChange={(customerUserId) => {
                  if (order.invoiceRecipient)
                    setOrder({
                      ...order,
                      invoiceRecipient: {
                        ...order.invoiceRecipient,
                        customerUserId,
                      },
                    });
                }}
              />
            </>
          )}
        </div>
      </Box>
      {invoice && (
        <Box title={t("pages.invoice.edit.furtherInformation")}>
          <TextArea
            resizable
            onChange={(description) => setInvoice({ ...invoice, description })}
            value={invoice.orderDetails}
            label={t("pages.invoice.edit.description")}
            disabled={isReadOnly}
          />
          <TextArea
            disabled={isReadOnly}
            resizable
            onChange={(orderDetails) =>
              setInvoice({ ...invoice, orderDetails })
            }
            value={invoice.orderDetails}
            label={t("pages.invoice.edit.orderDetails")}
          />
          <div className="three-columns">
            <Input
              type="text"
              onChange={(customerOrderNumber) =>
                setInvoice({ ...invoice, customerOrderNumber })
              }
              value={invoice.customerOrderNumber}
              label={t("pages.invoice.edit.orderNumber")}
              disabled={isReadOnly}
            />
            <Dropdown
              disabled
              label={t("pages.invoice.edit.currency")}
              selectedOption={order?.currencyId}
              onChange={() => {}}
              options={generateDropdownOptions(loadedCurrencies, "title", "id")}
            />
            <Dropdown
              disabled
              onChange={() => {}}
              label={t("pages.invoice.edit.office")}
              selectedOption={order?.officeId}
              options={generateDropdownOptions(loadedOffices, "name", "id")}
            />
            <Dropdown
              disabled
              onChange={() => {}}
              label={t("pages.invoice.edit.businessArea")}
              selectedOption={order?.businessAreaId}
              options={generateDropdownOptions(
                loadedBusinessAreas,
                "name",
                "id"
              )}
            />
            <Dropdown
              disabled
              onChange={() => {}}
              label={t("pages.invoice.edit.workingLocationCustomer")}
              selectedOption={order?.workingLocation.customerId}
              options={generateDropdownOptions(loadedCustomers, "name", "id")}
            />
            <Dropdown
              disabled
              onChange={() => {}}
              label={t("pages.invoice.edit.workingLocation")}
              selectedOption={order?.workingLocation.customerLocationId}
              options={generateDropdownOptions(
                loadedCustomerLocations,
                "name",
                "id"
              )}
            />
          </div>
        </Box>
      )}
      <Box
        title={
          <div className="box-title-wrapper">
            <p className="box__title">
              {t("pages.invoice.edit.invoicePositions")}
            </p>
            <div className="box-title-wrapper__icon-wrapper">
              {!isReadOnly && (
                <PlusIcon
                  title={t("general.icons.add")}
                  className="box-title-wrapper__icon-wrapper__icon"
                  onClick={() => toggleShowAddPopup(true)}
                />
              )}
            </div>
          </div>
        }
      >
        <Table
          rows={invoicePositionRows}
          header={
            t("pages.invoice.edit.positionHeaders", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
      <Box title={t("pages.invoice.edit.documentTitle")}>
        {invoice?.id && (
          <FileOverview
            bucket={DocumentBucket.INVOICE}
            path={`${invoice.id}/`}
            userId={user.id}
          />
        )}
        <div className="four-columns">
          <Button
            value={t("pages.invoice.edit.pdfPreview")}
            onClick={() =>
              invoice &&
              getInvoicePDFDocument(axios, invoice.id, user.id).then(
                (document) =>
                  document && setDocumentToShow(URL.createObjectURL(document))
              )
            }
          />
          <Button
            disabled={order?.withoutInvoice}
            value={t("general.buttons.send")}
            onClick={() => {
              setMailConfiguration({
                ...mailConfiguration,
                invoiceId: invoice?.id || "",
                type: MailType.INVOICE,
                userId: user.id,
                language: offer?.language || Language.DE,
              });
              toggleMailConfigOpen(true);
            }}
          />
          <Button
            value={t("general.buttons.times")}
            onClick={() => getProtocolDocument("time")}
          />
          <Button
            value={t("general.buttons.pieces")}
            onClick={() => getProtocolDocument("pieces")}
          />
        </div>
      </Box>
    </form>
  );
};
