import {
  Box,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Table,
  TableRow,
  TopBar,
  Wysiwyg,
} from "@sam/components";
import { TableHeader } from "@sam/components/src/Table/Table.types";
import {
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  CrmActionEntry,
  CrmEntry,
  Right,
  createNewCrmActionEntry,
  createNewCrmEntry,
  generateDropdownOptions,
  generateNotification,
  getAllCrmActionEntriesForCrmEntry,
  getCrmActionEntryById,
  updateCrmEntry,
  updateMultipleCrmActionEntries,
  useData,
} from "shared";
import { NotificationType } from "shared/src/notification/notification.types";
import dayjs from "shared/src/tools/Dayjs";
import { SaveButtons } from "../../components/saveButtons/SaveButtons";
import { useUser } from "../../components/UserContext";
import {
  convertCrmActionEntriesIntoTableRows,
  generateEmptyCrmActionEntry,
  generateEmptyCrmEntry,
} from "../../utils/crm/Crm.utils";
import { isUserAllowedToDo } from "../../utils/user/User.utils";

/**
 * Shortcut options to dispaly for due on
 */
const shortCutOptions: Map<string, number[]> = new Map<string, number[]>();
shortCutOptions.set("weeks", [1, 2, 3, 4]);
shortCutOptions.set("months", [1, 2, 3, 6, 12]);

export const CrmEntryEdit: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios } = useUser();
  const [isNonCustomer, toggleNonCustomer] = useState<boolean>(false);
  const location = useLocation<{
    crmEntry?: CrmEntry;
    actionEntry?: CrmActionEntry;
  }>();
  const [searchParams] = useSearchParams();

  const [entryToEdit, setEntryToEdit] = useState<CrmEntry>(
    location.state?.crmEntry ? location.state.crmEntry : generateEmptyCrmEntry()
  );
  const [newAction, setNewAction] = useState<CrmActionEntry>(
    location.state?.actionEntry
      ? location.state.actionEntry
      : generateEmptyCrmActionEntry({
          crmEntryId: location.state?.crmEntry?.id,
        })
  );
  const button = useRef<HTMLButtonElement>(null);
  const form = useRef<HTMLFormElement>(null);

  const [actionsToEdit, setActionsToEdit] = useState<CrmActionEntry[]>([]);
  const isEdit: boolean = useMemo(() => !!entryToEdit.id, [entryToEdit.id]);

  const { data: allOffices } = useData("OFFICES_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allTypes } = useData("CRM_TYPES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActions } = useData("CRM_ACTIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerUser } = useData("CUSTOMER_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });
  const { data: allUser } = useData("SCHAMBECK_USERS_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  //Hook to check if an entryId is provided in the Url and load the entry
  useEffect(() => {
    const searchParam: string | null = searchParams.get("entryId");
    if (location.state?.crmEntry || searchParam === null) return;
    getCrmActionEntryById(axios, searchParam).then((entry) => {
      entry && setEntryToEdit(entry);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // lazy loads the crmActionEntries which belong to the entry to edit
  // also checks if the entry to edit is one with a custom name
  useEffect(() => {
    if (entryToEdit.id) {
      getAllCrmActionEntriesForCrmEntry(entryToEdit.id, axios).then(
        (loadedEntries) => {
          setActionsToEdit(loadedEntries);
          const searchParam: string | null = searchParams.get("actionId");
          const editEntry: CrmActionEntry | undefined = loadedEntries.find(
            (actionEntry) => actionEntry.id === searchParam
          );
          const isDirectLink: boolean = !!searchParam && !!editEntry;
          if (isDirectLink) setNewAction(editEntry!);
        }
      );
      toggleNonCustomer(!!entryToEdit.customerName);
    }
    // needs to be disabled as the toggle should only trigger on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, entryToEdit.id]);

  /**
   * Submit handler to update or create a crm entry with corresponding actions
   * @param redirect decides if the method navigates back after success
   */
  const handleSubmit = (redirect: boolean): void => {
    button.current?.click();
    if (!form.current?.checkValidity()) return;

    if (entryToEdit.id) {
      Promise.all([
        updateCrmEntry(entryToEdit, axios),
        updateMultipleCrmActionEntries(actionsToEdit, axios),
      ]).then(([entryResp]) => {
        if (redirect && entryResp) navigate(-1);
        else if (entryResp)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.updateCrmEntry"),
          });
      });
    } else {
      createNewCrmEntry(
        { initialAction: newAction, newEntry: entryToEdit },
        axios
      ).then((resp) => {
        if (redirect && resp) navigate(-1);
        else if (resp)
          generateNotification({
            type: NotificationType.SUCCESS,
            value: t("general.notification.success.createCrmEntry"),
          });
      });
    }
  };

  /**
   * Helper to update the local entry to edit
   * @param newValue The user entered value
   * @param key The key to update
   */
  const updateEntryToEdit = (
    newValue: string | string[],
    key: keyof CrmEntry
  ): void => {
    setEntryToEdit((old) => ({ ...old, [key]: newValue }));
  };

  /**
   * Helper to update the new action
   * @param newValue The user entered value
   * @param key The key to update
   */
  const updateNewAction = (
    newValue: string | string[] | Date | boolean | number | undefined,
    key: keyof CrmActionEntry
  ): void => {
    setNewAction((old) => ({ ...old, [key]: newValue }));
  };

  /**
   * Helper to update a action in the array of all crm entry action
   * @param newValue The user entered value
   * @param key The key to update
   * @param actionId The id of the edited instance
   */
  const updateActions = useCallback(
    (
      newValue: string | string[] | Date | boolean | undefined,
      key: keyof CrmActionEntry,
      actionId?: string
    ): void => {
      const indexToUpdate: number = actionsToEdit.findIndex(
        (action) => action.id === actionId
      );
      setActionsToEdit((old) => {
        const localCopy: CrmActionEntry[] = [...old];
        localCopy[indexToUpdate] = {
          ...localCopy[indexToUpdate],
          [key]: newValue,
        };
        return localCopy;
      });
    },
    [actionsToEdit]
  );

  /**
   * Helper to handle the add event of a new crmActionEntry. Resets the form
   * on success
   * @param evt The form event
   */
  const addNewActionEntry = (
    evt: FormEvent,
    type: "update" | "create"
  ): void => {
    evt.preventDefault();
    createNewCrmActionEntry(newAction, axios).then((resp) => {
      if (resp) {
        if (type === "create") setActionsToEdit((old) => [...old, resp]);
        else
          setActionsToEdit((old) =>
            old.map((entry) => (entry.id === resp.id ? resp : entry))
          );
        setNewAction(
          generateEmptyCrmActionEntry({ crmEntryId: entryToEdit.id })
        );
      }
    });
  };

  // holds the table entries for the crmActionEntries
  const actionRows: TableRow[] = useMemo(
    (): TableRow[] =>
      convertCrmActionEntriesIntoTableRows(
        actionsToEdit,
        allActions,
        allTypes,
        entryToEdit,
        allUser,
        updateActions,
        setNewAction
      ),
    [actionsToEdit, allActions, allUser, allTypes, entryToEdit, updateActions]
  );

  /**
   * Holds the correct contact fields. Either dropdowns or inputs
   */
  const contactFields: ReactNode = useMemo((): ReactNode => {
    if (isNonCustomer) {
      return (
        <>
          <Input
            type="text"
            value={entryToEdit.customerName}
            onChange={(newValue) => updateEntryToEdit(newValue, "customerName")}
            label={t("pages.crm.edit.customerId")}
          />
          <Input
            type="text"
            value={entryToEdit.contactName}
            onChange={(newValue) => updateEntryToEdit(newValue, "contactName")}
            label={t("pages.crm.edit.contactId")}
          />
        </>
      );
    } else {
      return (
        <>
          <Dropdown
            options={generateDropdownOptions(allCustomer, "name", "id")}
            onChange={(customer) => updateEntryToEdit(customer, "customerId")}
            label={t("pages.crm.edit.customerId")}
            selectedOption={entryToEdit.customerId}
          />
          <Dropdown
            disabled={!entryToEdit.customerId}
            options={generateDropdownOptions(
              allCustomerUser.filter(
                (user) => user.customerId === entryToEdit.customerId
              ),
              ["lastName", "firstName"],
              "id"
            )}
            multi
            selectedMultiOptions={entryToEdit.contactIds}
            onChangeMultiple={(contactIds) =>
              updateEntryToEdit(contactIds, "contactIds")
            }
            label={t("pages.crm.edit.contactId")}
          />
        </>
      );
    }
  }, [
    allCustomer,
    allCustomerUser,
    entryToEdit.contactIds,
    entryToEdit.contactName,
    entryToEdit.customerId,
    entryToEdit.customerName,
    isNonCustomer,
    t,
  ]);

  /**
   * Helper to handle the checkbox click for a non customer contact
   *
   * @param toggled if the checkbox is checked or not
   */
  const handleNonCustomerToggle = (toggled: boolean): void => {
    if (toggled) {
      updateEntryToEdit("", "customerId");
      updateEntryToEdit("", "contactIds");
    } else {
      updateEntryToEdit("", "customerName");
      updateEntryToEdit("", "contactName");
    }
    toggleNonCustomer(toggled);
  };

  /**
   * Helper method to update the dueDate with the shortcut Icons
   * @param amount amount in days or weeks
   * @param type string for weeks or months
   */
  const handleShortCutClick = (amount: number, type: "weeks" | "months") => {
    const newDueDate: Date = dayjs(new Date()).add(amount, type).toDate();
    updateNewAction(newDueDate, "dueDate");
  };

  /**
   * Util method to generate shortCutOptions
   * @returns  Array of JSX.Elements
   */
  const getShortCutOptions = (): JSX.Element[] => {
    const generatedButtons: JSX.Element[] = [];
    shortCutOptions.forEach((value, key) => {
      generatedButtons.push(
        <>
          <div className="crm-edit__shortcut-wrapper">
            <p className="crm-edit__shortcut-wrapper__title">
              {t(`pages.crm.edit.${key}`)}
            </p>
            {value.map(
              (entryValue) =>
                (key === "weeks" || key === "months") && (
                  <Button
                    type="button"
                    value={entryValue.toString()}
                    onClick={() => handleShortCutClick(entryValue, key)}
                  />
                )
            )}
          </div>
        </>
      );
    });
    return generatedButtons;
  };

  return (
    <>
      <form
        ref={form}
        onSubmit={(evt) => evt.preventDefault()}
        onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.preventDefault()}
      >
        <TopBar
          onBackClick={() => navigate(-1)}
          title={t(
            `pages.crm.edit.topBarHeadline${isEdit ? "Edit" : "Create"}`
          )}
        >
          <SaveButtons buttonRef={button} handleSubmit={handleSubmit} />
        </TopBar>
        <Box title={t("pages.crm.edit.infoTitle")}>
          <Checkbox
            isChecked={isNonCustomer}
            onCheck={handleNonCustomerToggle}
            label={t("pages.crm.edit.toggleNonCustomer")}
          />
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptions(allOffices, "name", "id")}
              onChange={(office) => updateEntryToEdit(office, "officeId")}
              label={t("pages.crm.edit.officeId")}
              selectedOption={entryToEdit.officeId}
            />
            <div>
              <Dropdown
                options={generateDropdownOptions(allTypes, "title", "id")}
                onChange={(type) => updateEntryToEdit(type, "typeId")}
                label={t("pages.crm.edit.type")}
                selectedOption={entryToEdit.typeId}
              />
            </div>
          </div>
          <div className="three-columns">{contactFields}</div>
        </Box>
      </form>
      <Box
        title={
          <div className="flex-row">
            <p className="box__title">{t("pages.crm.edit.actionTitle")}</p>
            {newAction.id && (
              <Button
                value={t("general.buttons.createNewAction")}
                onClick={() =>
                  setNewAction(
                    generateEmptyCrmActionEntry({
                      crmEntryId: entryToEdit.id,
                      note: "",
                    })
                  )
                }
              />
            )}
          </div>
        }
      >
        <form
          onSubmit={(evt) =>
            newAction.id
              ? addNewActionEntry(evt, "update")
              : addNewActionEntry(evt, "create")
          }
        >
          <div className="three-columns">
            <Dropdown
              options={generateDropdownOptions(allActions, "title", "id")}
              selectedOption={newAction.actionId}
              required
              onChange={(customer) => updateNewAction(customer, "actionId")}
              label={t("pages.crm.edit.actionType")}
            />
            <Dropdown
              multi
              options={generateDropdownOptions(
                allUser.filter((user) =>
                  isUserAllowedToDo(user.right, Right.CRM_OVERVIEW)
                ),
                ["firstName", "lastName"],
                "id"
              )}
              label={t("pages.crm.edit.internalContact")}
              onChangeMultiple={(contacts) =>
                updateNewAction(contacts, "internalContact")
              }
              selectedMultiOptions={newAction.internalContact}
            />
            <div className="flex-column">
              <Checkbox
                label={t("pages.crm.edit.revenueRelevant")}
                isChecked={newAction.revenueRelevant}
                onCheck={(isRelevant) =>
                  updateNewAction(isRelevant, "revenueRelevant")
                }
              />
              {newAction.revenueRelevant && (
                <div className="flex-row">
                  <Input
                    value={newAction.revenue}
                    onChangeNumber={(revenue) =>
                      updateNewAction(revenue, "revenue")
                    }
                    type="number"
                  />
                  €
                </div>
              )}
            </div>
          </div>
          <div>
            <Wysiwyg
              title={t("pages.crm.edit.note")}
              initialContent={newAction.note}
              onChange={(note) => updateNewAction(note, "note")}
            />
          </div>
          <Input
            type="date"
            label={t("pages.crm.edit.dueDate")}
            onChangeDate={(newDate) => updateNewAction(newDate, "dueDate")}
            value={newAction.dueDate}
          />
          {getShortCutOptions()}
          {entryToEdit.id && (
            <>
              <Button
                value={
                  newAction.id
                    ? t("general.buttons.update")
                    : t("pages.crm.edit.addButton")
                }
                type="submit"
              />
              <Table
                header={
                  t("pages.crm.edit.actionTableHeader", {
                    returnObjects: true,
                  }) as TableHeader[]
                }
                rows={actionRows}
              />
            </>
          )}
        </form>
      </Box>
    </>
  );
};
