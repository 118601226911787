import { Box, Button, Table, TableRow, TopBar } from "@sam/components";
import { TableHeader, TableKey } from "@sam/components/src/Table/Table.types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CrmActionEntry,
  Language,
  updateSchambeckUserTableConfig,
  useData,
} from "shared";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useUser } from "../../components/UserContext";
import { convertCrmEntriesIntoTableEntries } from "../../utils/crm/Crm.utils";

export const CrmEntryOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { axios, updateContextUser, user } = useUser();
  const [filterValue, setFilterValue] = useState<string>("");
  const [viewType, setViewType] = useState<"all" | "open" | "done">("open");

  const { data: allEntries } = useData("CRM_ENTRIES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allSimpleUsers } = useData("SIMPLEUSERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActionEntries } = useData("CRM_ACTION_ENTRIES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allTypes } = useData("CRM_TYPES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allActions } = useData("CRM_ACTIONS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allOffices } = useData("OFFICES_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomerUser } = useData("CUSTOMER_USERS_ALL", {
    config: { fallbackData: [] },
  });
  const { data: allCustomer } = useData("CUSTOMER_ALL_ACTIVE", {
    config: { fallbackData: [] },
  });

  /**
   * TableRows containing all crm manage entries
   */
  const rows: TableRow[] = useMemo((): TableRow[] => {
    const actionsToShow: CrmActionEntry[] =
      viewType === "all"
        ? allActionEntries
        : allActionEntries.filter((entry) => {
            if (viewType === "done") return entry.done;
            else return !entry.done;
          });
    return convertCrmEntriesIntoTableEntries(
      allEntries,
      actionsToShow,
      allTypes,
      allActions,
      allSimpleUsers,
      navigate,
      allCustomer,
      allCustomerUser,
      allOffices,
      user.language || Language.DE
    );
  }, [
    allActionEntries,
    allActions,
    allCustomer,
    allCustomerUser,
    allEntries,
    allOffices,
    allSimpleUsers,
    allTypes,
    navigate,
    user.language,
    viewType,
  ]);

  return (
    <>
      <TopBar
        title={t(`pages.crm.overview.topBarHeadline`)}
        onSearch={setFilterValue}
      >
        <PlusIcon
          title={t("general.icons.add")}
          onClick={() => navigate("edit")}
        />
      </TopBar>
      <Box>
        <div className="three-columns">
          <Button
            onClick={() => setViewType("open")}
            value={t("general.buttons.open")}
            active={viewType === "open"}
          />
          <Button
            onClick={() => setViewType("done")}
            value={t("general.buttons.done")}
            active={viewType === "done"}
          />
          <Button
            onClick={() => setViewType("all")}
            active={viewType === "all"}
            value={t("general.buttons.all")}
          />
        </div>
        <Table
          tableConfig={user.tableConfig?.get(TableKey.CRM_ENTRY_OVERVIEW)}
          updateTableConfig={(updatedHeader) => {
            updateSchambeckUserTableConfig(
              axios,
              user,
              TableKey.CRM_ENTRY_OVERVIEW,
              updatedHeader
            ).then(
              (updatedUser) => updatedUser && updateContextUser(updatedUser)
            );
          }}
          filterValue={filterValue}
          rows={rows}
          header={
            t("pages.crm.overview.tableHeader", {
              returnObjects: true,
            }) as TableHeader[]
          }
        />
      </Box>
    </>
  );
};
